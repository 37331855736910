:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.lead-list-container {
  align-items: center;
  justify-items: center;

  align-self:center;
  justify-self: center;

  overflow: hidden;

}

.list-container{
  width:100vw;
}

.spinner{
  color: var(--blue);
  margin-top:2vh;
  align-self:center;
  justify-self: center;
}

.emptyList{
  align-self: center;
  color:var(--blue);
  font-size: 2vh;
  font-weight: 500;
}

.create-lead-floating-button{
  position: absolute;
  background-color: var(--white);
  bottom: 125px;
  right: 25px;
  font-size: 50px;
  padding: 15px;
  margin: 0;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: var(--blue);
}

 /* Hide scrollbar for Chrome, Safari and Opera */
 .lead-list-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.lead-list-container {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.emptyComponent{
  display: flex;
  justify-content: center;
  width: 10vh;
  height: 10vh;
}