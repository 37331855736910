:root {
    --blue: #1D1F66;
    --white: #ffffff;
  }


.lead-title-container{


}

.lead-title-text{
    justify-content: space-between;
}

.lead-title-input{
    color: var(--blue);
    border-style: hidden;
    font-size: 3vh;
    font-weight: 500;
    width:100%;
}
.lead-title-buttons{

}

.lead-title-status{
    color: var(--blue);
}
