:root {
    --blue: #1D1F66;
    --white: #ffffff;
  }

.header-container{
    display: flex;
    flex-direction: row;
    align-items: center;

    background-color: var(--blue);
    background-image:url("../../imgs/HeaderBg.png");
    background-size: 60vh;

    left: 0;
    top: 0;
    margin-top: 0;

    width: 100%;
    height: 20vh;
    z-index: 2;
}

.header-container h1{
  color: var(--white);
  padding: 5%;
  font-size: 5vh;

}

.header-container h1::first-letter {
  text-transform:capitalize;
}

.back-button{
  width:5%;
  height:20vh;

  font-size: xx-large;
  color: var(--white);
  background-color: transparent;
  font-weight: 900;

  border-style: hidden;

  position: absolute;

}

.header-search-input{
  margin-right: 5vw;
}

.header-search-input-component{
  padding:5px;
  border-radius: 5px;
  color: var(--white);

  height:3vh;

  background-color: #FFFFFF4D;
  padding-left: 1vw;
  border-style: hidden;
}

::placeholder{
  opacity: 100%;
  color:var(--white)
}
