:root {
  --blue: #1D1F66;
  --white: #ffffff;
}

.search-header-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: var(--blue);
  background-image:url("../../imgs/HeaderBg.png");
  background-size: 80vh;

  left: 0;
  top: 0;
  margin-top: 0;

  width: 100%;
  height: 22vh;
  z-index: 2;
}

.search-header-container h1{
  color: var(--white);
  padding: 0.2vh;
  font-size: 5vh;
  margin-top: 0;

}

.search-header-container h1::first-letter {
  text-transform:capitalize;
}

.search-header-search-input{
  align-items: center;
  padding:0.2vw;
}

.search-header-search-input-component{
  padding:5px;
  border-radius: 5px;
  color: var(--white);

  height:3vh;

  background-color: #FFFFFF4D;
  padding-left: 1vw;
  border-style: hidden;

  width:70%;
}

.search-list-container{
  height:78vh;
  overflow-y: scroll;
}

::placeholder{
  opacity: 100%;
  color:var(--white)
}

.emptyList{
  text-align: center;
  align-items: center;
}
.search-empty-component{
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}