:root {
    --blue: #1D1F66;
    --white: #ffffff;
  }

.historial-container{
  margin: 2vw;
  color:var(--blue);
}

.historial-empty{
  margin:10vh;
  text-align: center;
  display: flex;
  justify-content: center;
}
