.one-lead-container{
    padding-right:2vw;
    padding-left:2vw;

    padding-top:3vh;
    padding-bottom:2vh;

    overflow: scroll;
}

.one-lead-title{
}
.create-task-input-container{
    margin:5vh
}

.one-lead-buttons-div{
align-self: center;
justify-self: center;
align-items: center;
justify-content: center;
padding: 35px;
}

.one-lead-button{
    margin:10px;
    height: 10vh;
    width: fit-content;
    padding: 2vw;
    border-radius: 6px;
    border-color: var(--blue);
    border-style: solid;
    border-width: 1px;
    font-size:1cqmax;
}

.one-lead-button-accept{
    background-color: var(--blue);
    color: var(--white);
    font-weight: 700;
}
.one-lead-button-accept:active{
    background-color: var(--white);
    color: var(--blue);
}

.one-lead-button-cancel{
    background-color: var(--white);
    color: var(--blue);
    font-weight: 700;
}
.one-lead-button-cancel:active{
    background-color: var(--blue);
    color: var(--white);
}

.create-task-user-select-div{
    margin-top:2vh;
    color:var(--blue);
}

.create-task-user-select{
    margin-top:0;
}

.create-task-user-select-label{
    color:var(--blue);
    font-size: 2vh;
    margin:0;
    margin-bottom: 0.5vh;
}

.create-task-user-select{
    border-color: var(--blue);
}