:root {
  --blue: #1D1F66;
  --white: #ffffff;
}


.App {
  text-align: center;
}

.main-app-container{
  display: flex;
  flex-direction: column;
  background-color: var(--white);
  position: fixed;

  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}
