:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.add-task-container{
    display:flex;
    flex-direction: column;

    width:100%;
    min-width: 47.5vw;
}

.add-task-link{
    padding:5px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--blue);
    background-color: var(--white);
    height:3vh;

    justify-content: center;
    color: var(--blue);
    font-weight: 1000;
    font-size: 3vh;
    text-decoration: none;
    align-items: center;

    display: flex;
    
}
.add-task-link:active{
    background-color: var(--blue);
}

.add-task-label{
    color: var(--blue);
    font-size: 2vh;
    margin-bottom: 0.5vh;
}
