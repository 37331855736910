:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.input-container{
    display:flex;
    flex-direction: column;
    width: 100%;
}

.input-label{
    color: var(--blue);
    font-size: 2vh;
    margin-bottom: 0.5vh;
}

.address-input-component{
    padding:5px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--blue);
    color: var(--blue);

    height:3vh;
    min-width: 35vw;
    flex:1;
}

.address-input-and-button-container{
    display:flex;
    flex-direction: row;
    flex:1;
}

.maps-button-container{
    display: flex;
    flex: 1;
    height:4vh;
    border-radius: 5px;
    background-color: var(--blue);
    text-align: center;
    justify-content: center;
    align-items: center;
}

.maps-button-text{

    height:100%;
    width:100%;

    justify-content: center;
    color: var(--blue);
    font-weight: 1000;
    font-size: small;
    text-decoration: none;
    align-items: center;

    display: flex;

    color: white;
    text-decoration: none;
    text-align: center;
    
}

.location-search{
    min-width: 46.5vw;
}