.one-lead-container{
    padding-right:2vw;
    padding-left:2vw;

    padding-top:3vh;
    padding-bottom:2vh;

    overflow: scroll;
}

.one-lead-title{
    display: flex;
    justify-content: space-between;
}
.one-lead-call-buttons-container{
    height: 10vh;
    align-items: center;
}
#one-lead-call-icon{
    font-size: 4vh;
    color:var(--blue);
}
#one-lead-wpp-icon{
    font-size: 4vh;
    color:green;
}
.one-lead-call-button{
    text-decoration: none;
    margin:10px;
    height: 6vh;
}


.one-lead-inputs{
}

.one-lead-buttons-div{
    display:flex;
    justify-content: center;
    padding: 35px;
}

.one-lead-button{
    margin:10px;
    height: 10vh;
    width: fit-content;
    padding: 2vw;
    border-radius: 6px;
    border-color: var(--blue);
    border-style: solid;
    border-width: 1px;
    font-size:1cqmax;
}

.one-lead-button-accept{
    background-color: var(--blue);
    color: var(--white);
    font-weight: 700;
}
.one-lead-button-accept:active{
    background-color: var(--white);
    color: var(--blue);
}

.one-lead-button-cancel{
    background-color: var(--white);
    color: var(--blue);
    font-weight: 700;
}
.one-lead-button-cancel:active{
    background-color: var(--blue);
    color: var(--white);
}
