:root {
  --blue: #1D1F66;
  --white: #ffffff;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  background-image:url("../imgs/bg.jpg");
  background-size: 100vw 100vh;
  background-color: var(--blue);

  left: 0;
  top: 0;
  margin-top: 0;

  width: 100vw;
  height: 100vh;

  justify-content: center;
}
.crm-img-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom:30px;
}
.crm-img-div img {
  width: max-content;
  height: 25vh;
}
.crm-img-class{
  display:flex;
}

.crm-img-class-not{
  opacity: 0;
  height: 25vh;
}


.login-form-div {
  display: flex;
  flex-direction: column;
}

.inputs-div {
  align-self: center;
  justify-content: center;

  display: flex;
  flex-direction: column;
}

.inputs-div input {

  align-self: center;
  justify-content: center;

  margin-bottom: 1vh;
  height: 4vh;
  width: 15vw;
  border-radius: 6px;
  border-style: hidden;
}


.button-div{
  align-self: center;
  justify-content: center;
}

.button-div button{
  margin-top: 2vh;
  height: 4vh;
  width: 10vw;
  border-radius: 6px;
  border-style: hidden;
  background-color: var(--white);
}

.login-error-message{
  color:red;
  text-align: center;
}


.spinner-white{
  color: var(--white);
  margin-top:2vh;
  align-self:center;
  justify-self: center;
}