:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.input-container{
    display:flex;
    flex-direction: column;
    width: 100%;
}

.input-label{
    color: var(--blue);
    font-size: 2vh;
    margin-bottom: 0.5vh;
}

.input-component{
    padding:5px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--blue);
    color: var(--blue);

    height:3vh;
    min-width: 46.5vw;
}

textarea.input-component{
    height: 10vh;
}
