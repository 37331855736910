:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.separator{
    background-color: var(--blue);
    height:1px;
    width:96vw;
    margin-bottom:2vh;
}
