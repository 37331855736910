:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.select-container{
    display:flex;
    flex-direction: column;
}

.select-label{
    color: var(--blue);
    font-size: 2vh;
    margin-bottom: 0.5vh;
}

.select-component{
    padding:5px;
    border-radius: 5px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--blue);
    background-color: var(--white);
    color: var(--blue);
    height:4vh;

    min-width: 47.5vw;

}
