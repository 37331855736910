.one-contact-container{
    padding-right:2vw;
    padding-left:2vw;

    padding-top:3vh;
    padding-bottom:2vh;

    overflow: scroll;
}

.one-contact-title{
}
.one-contact-inputs{
}

.one-contact-buttons-div{
    display:flex;
    justify-content: center;
    padding: 35px;
}

.one-contact-button{
    margin:10px;
    height: 10vh;
    width: fit-content;
    padding: 2vw;
    border-radius: 6px;
    border-color: var(--blue);
    border-style: solid;
    border-width: 1px;
    font-size:1cqmax;
}

.one-contact-button-accept{
    background-color: var(--blue);
    color: var(--white);
    font-weight: 700;
}
.one-contact-button-accept:active{
    background-color: var(--white);
    color: var(--blue);
}

.one-contact-button-cancel{
    background-color: var(--white);
    color: var(--blue);
    font-weight: 700;
}
.one-contact-button-cancel:active{
    background-color: var(--blue);
    color: var(--white);
}
