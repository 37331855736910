:root {
    --blue: #1D1F66;
    --white: #ffffff;
    --grey: #DDDDDD;
  }

.lead-card {
  display: flex;
  background-color: var(--white);
  flex-direction: row;

  border: solid;
  border-width: 1px;
  border-color: var(--grey);

  align-items: center;

  height:10vh;
}

.lead-card-items {
  display: flex;
  flex-direction: row;

  width:100%;
  height: 100%;

  justify-content: space-between;
  align-items: center;

  background-color: var(--white);
  text-decoration: none;

  padding-right: 5%;
  padding-left: 3%;

}

.name-and-logo{
  display: flex;
  align-items: center;
  justify-content:left;
  width: 30%;
  color: var(--blue);
}

.ph-fill{
  margin-right: 5%;
}

.lead-card-items i{
  font-size: 5vh;
}
.lead-card-items .list-text{
  color:var(--blue);
  font-size: 2.5vh;
}