:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.list-container{
  overflow-y: auto;
  margin-bottom: 10vh;

}

.list {
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  left: 0;
}

.list li {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}
