.modal {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.60);
  display: none;
  justify-content: center;
  align-items: center;
}

/* css del contenido del popup de agradecimiento */

.modal-container {
  position: relative;
  min-width: 10px;
  min-height: 10px;
  overflow-y: auto;
  background-color: #fff;
  border-radius: 8px;
  overflow-x: hidden;
}


.modal.is-open {
  display: flex;
}

@media screen and (min-width: 281px) {
  .modal-container {
    position: relative;
    min-width: 300px;
    min-height: 10px;
    overflow-y: auto;
    background-color: #fff;
    border-radius: 8px;
    overflow-x: hidden;
  }
}

@media screen and (min-width: 361px) {
  
  .modal-container  .thanks-title{
    font-size: 28px;
  }
}

@media screen and (min-width: 376px) {
  .modal-container {
    min-width: 350px;
    min-height: 10px;
  }
  
  .modal-container  .thanks-title{
    top: 200px;
    font-size: 32px;
  }
  
  .modal-container .thanks-sub-title{
    font-size: 22px;
  }
}

@media screen and (min-width: 700px){
  .modal-container {
    position: relative;
    min-width: 650px;
    min-height: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

}

@media screen and (min-width: 1250px) {

  .modal-container {
    position: relative;
    min-width: 1100px;
    min-height: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }

}