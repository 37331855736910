:root {
    --blue: #1D1F66;
    --white: #ffffff;
  }

.bottom-menu{
    display: flex;
    position: fixed;
    flex-direction: row;
    
    justify-content:space-around;
    align-items: center;

    background-color: var(--white);

    left: 0;
    bottom: 0;
    margin-bottom: 0;

    width:100%;
    height: 10vh;
    box-sizing: border-box;
    box-shadow: 0px -2px 20px 1px grey;
  }

.bottom-menu .bottom-menu-link{
    display: flex;

    font-size: 5vh;
    text-decoration: none;
    color: var(--blue);
  }

.bottom-menu li {
    text-align: center;
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
