:root {
    --blue: #1D1F66;
    --white: #ffffff;
  }

.top-menu{
    display: flex;
    flex-direction: row;
    
    justify-content:space-around;
    align-items: center;
    overflow: scroll;

    background-color: var(--white);

    left: 0;
    top: 0;
    margin-top: 0;

    height: 7vh;
    box-sizing: border-box;
    box-shadow: 0px -2px 20px 1px grey;

  }

.top-menu-link{
    display: flex;

    font-size: 5vh;
    text-decoration: none;
    color: var(--blue);


    align-items: center;
    justify-content: center;
  }

.top-menu-link-selected{
    display: flex;
    text-decoration: none;
    color: var(--blue);

    border-bottom:solid;
    border-bottom-width: 5px;
    border-bottom-color: var(--blue);

    justify-content: center;

}

.top-menu h1{
  font-size: 2vh;
  width:35vw;
}

.top-menu-container h1::first-letter {
  text-transform:capitalize;
}

.top-menu li {
    text-align: center;
    list-style-type: none;
    margin: 0;
    padding: 0;

    width: 100%;
  }

@media screen and (min-width: 1001px) {
  .top-menu h1{
    font-size: 2vh;
    width:max-content;
  }  
}
