:root {
    --blue: #1D1F66;
    --white: #ffffff;
    --grey: #DDDDDD;
  }

.task-card {
  display: flex;
  background-color: var(--white);
  flex-direction: row;

  border: hidden;
  border-width: 1px;
  border-color: var(--grey);

  align-items: center;

  height:10vh;
}

.task-card-items {
  display: flex;
  flex-direction: row;

  width:100%;
  height: 100%;

  justify-content: space-between;
  align-items: center;

  background-color: var(--white);
  text-decoration: none;

  padding-right: 5%;
  padding-left: 3%;

}

.name-and-logo{
  display: flex;
  align-items: center;
  justify-content:left;
  width: 30%;
  color: var(--blue);
}

.ph-fill{
  margin-right: 5%;
}

.task-card-items i{
  font-size: 5vh;
}
.task-card-text{
  text-align: left;

  justify-content: left;

  color:var(--blue);
  font-size: 2.5vh;
}

.task-card-button{
  padding-right:3vw;
  border-style: hidden;
  background-color: var(--white);
  color: var(--blue);
  font-size: 3vh;
  cursor: pointer;
}

.task-card-parent-name{
  font-weight: 300;
  font-size: 2vh;
  margin:0;
}
