:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.settings-container{
  margin-left:2vw;
  margin-right:2vw;

  margin-top:2vh;
  margin-bottom:2vh;
  color:var(--blue);
  overflow: scroll;


}

.settings-user-fullname{

}

.settings-username{

}

.settings-change-language-button{
  color:var(--blue);
  text-decoration: none;

  display: flex;
  align-items: center;
}
.ph-globe-hemisphere-west{
  margin:0;
  font-size: 1.8vw;
  padding-right: 0.5vw;
}

.settings-language-select-form{
  margin:2vw;
}
.settings-modal-title{
  margin-left: 2%;
}
.settings-modal-separator{
  width:96%;
  margin-left: 2%;
}

/*--------------------*/
/*--------------------*/
.settings-view-users-button{
  text-decoration: none;
  color:var(--blue);
  margin:0;

  display: flex;
  align-items: center;
}
.ph-user-circle-gear{
  margin:0;
  font-size: 1.8vw;
  padding-right: 0.5vw;

}


/*--------------------*/
/*--------------------*/
.settings-reload-config-button{
  text-decoration: none;
  color:var(--blue);
  margin:0;

  display: flex;
  align-items: center;
}
.ph-gear-fine{
  margin:0;
  font-size: 1.8vw;
  padding-right: 0.5vw;

}


/*--------------------*/
/*--------------------*/
.settings-logout-button{
  text-decoration: none;
  color:var(--blue);
  margin:0;

  display: flex;
  align-items: center;
}
.ph-sign-out{
  margin:0;
  font-size: 1.8vw;
  padding-right: 0.5vw;

}

.settings-button-container{
  margin-top: 2vh;
}


.settings-language-select-button-container{
  display: flex;
  align-items: center;
  justify-content: center;
}
.settings-language-select-button{
  padding:2vh;
  margin-top: 2vh;
  border-radius: 8px;
  align-self: center;
  background-color: var(--blue);
  color: var(--white);
  font-weight: 700;
}
