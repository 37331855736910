:root {
    --blue: #1D1F66;
    --white: #ffffff;
}

.contacts-list-container {
  align-items: center;
  justify-items: center;

  align-self:center;
  justify-self: center;

  overflow: scroll;

}

.list-container{
  width:100vw;
}

.spinner{
  color: var(--blue);
  margin-top:2vh;
  align-self:center;
  justify-self: center;
}
