:root {
  --blue: #1D1F66;
  --white: #ffffff;
}

.call-list-container{
  overflow-y: auto;
  margin-bottom: 15vh;

}

.call-list {
  background-color: var(--blue);
  display: flex;
  flex-direction: column;
  margin: 0;
  margin-bottom: 0vh;
  padding: 0;
  left: 0;
}

.call-list li {
  text-align: center;
  list-style-type: none;
  margin: 0;
  padding: 0;
}


.call-list-section-title{
  color:var(--blue);
  background-color: var(--white);
}
.call-list-section-title-text{
  font-size: 4vh;
  text-align: left;
  margin-left: 1vw;
}


.call-list-section-footer{
  color:var(--blue);
  background-color: var(--white);
}

.add-task-list-link{
  padding: 0;
  margin: 0;
  font-size: 4vh;
  font-weight: 900;
  width:100%;
  background-color: transparent;
  border-style: hidden;
  cursor: pointer;
  color:var(--blue);
  text-decoration: none;
}

.call-list-section-footer-separator{
  align-items: center;
}